import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox, RHFSelect } from '../../../components/hook-form';

// ----------------------------------------------------------------------

// const defaultValuesByType = {
//   sa: {
//     username: 'SuperAdminDemo',
//     password: '123456',
//   },
//   agent: {
//     username: 'AgentDemo',
//     password: '123456',
//   },
// };

export default function LoginForm({ loginType }) {
  const { login } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const [formError, setFormError] = useState('');

  useEffect(() => {
    loadCaptchaEnginge(4, 'rgb(33, 43, 54)', '#fff', 'numbers');
  }, []);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().min(6).required().label('Password'),
    userType: Yup.string(),
    captcha: Yup.string()
      .min(4)
      .test('is-valid-captcha', 'Invalid Captcha', (value) => validateCaptcha(value, false))
      .required()
      .label('Captcha'),
  });

  const defaultValues = {
    username: '',
    password: '',
    captcha: '',
    userType: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      methods.handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    setValue('userType', loginType);
  }, [loginType]);

  // useEffect(() => {
  //   if (loginType) {
  //     setValue('username', defaultValuesByType[loginType].username);
  //     setValue('password', defaultValuesByType[loginType].password);
  //   }
  // }, [loginType, setValue]);

  const onSubmit = async (data) => {
    try {
      await login(data);
    } catch (error) {
      console.error(error);

      reset();

      setFormError(error?.response?.data?.message || error.message);
    }
  };

  const doSubmit = () => {
    // <! --   let's assume there is an HTML input text box with id 'user_captcha_input' to get user input -->
    // const user_captcha_value = document.getElementById('user_captcha_input').value;
    // if (validateCaptcha(user_captcha_value) == true) {
    //   alert('Captcha Matched');
    // } else {
    //   alert('Captcha Does Not Match');
    // }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!formError && <Alert severity="error">{formError}</Alert>}

        {/* <RHFSelect name="language" label="language">
          <option value="" />
          <option value="English">English</option>
          <option value="Bahasa Melayu">Bahasa Melayu</option>
        </RHFSelect> */}

        {/* <RHFSelect name="country" label="Country">
          <option value="" />
          <option value="Malaysia">Malaysia</option>
          <option value="Thailand">Thailand</option>
        </RHFSelect> */}

        <RHFTextField
          name="username"
          label="Username"
          sx={{
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: '#fff',
              },
            },
            '& .MuiOutlinedInput-input': {
              color: '#fff !important',
            },
          }}
        />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: '#fff',
              },
            },
            '& .MuiOutlinedInput-input': {
              color: '#fff !important',
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" tabindex="-1">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Stack direction={'row'} spacing={1} alignItems="center">
          <RHFTextField
            name="captcha"
            label="Captcha Code"
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: '#fff',
                },
              },
              '& .MuiOutlinedInput-input': {
                color: '#fff !important',
              },
            }}
          />
          <Box
            sx={{
              height: 56,
              border: '1px solid rgb(220, 224, 228)',
              borderRadius: '8px',
              pt: '5px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LoadCanvasTemplateNoReload />
          </Box>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Login
        </LoadingButton>
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack> */}
    </FormProvider>
  );
}
