import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router';
import useAuth from '../hooks/useAuth';
import { PATH_GROUPS } from './paths';
import { PATH_AFTER_LOGIN } from '../config';

const PathAfterLogin = (props) => {
  const { user } = useAuth();
  return <Navigate to={user?.role !== 'runner' ? PATH_AFTER_LOGIN : PATH_GROUPS.root} replace />;
};

PathAfterLogin.propTypes = {};

export default PathAfterLogin;
