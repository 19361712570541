import { Suspense, lazy, useMemo } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
import { filterNavs } from '../utils/formatObject';
import PathAfterLogin from './PathAfterLogin';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();

  const filteredRoutes = useMemo(() => {
    const initialRoutes = [
      {
        path: 'auth',
        children: [
          {
            path: 'login',
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            ),
          },
          {
            path: 'register',
            element: (
              <GuestGuard>
                <Register />
              </GuestGuard>
            ),
          },
          { path: 'login-unprotected', element: <Login /> },
          { path: 'register-unprotected', element: <Register /> },
          { path: 'reset-password', element: <ResetPassword /> },
          { path: 'new-password', element: <NewPassword /> },
          { path: 'verify', element: <VerifyCode /> },
        ],
      },

      // MY PROFILE
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <PathAfterLogin />, index: true },
          { path: 'profile', element: <MyProfile /> },
        ],
      },

      // MEMBER PROFILE
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <PathAfterLogin />, index: true },
          { path: 'member/:memberID', element: <MemberProfile /> },
        ],
      },

      // REPORTS
      {
        path: 'report',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <PathAfterLogin />, index: true },
          { path: 'group', element: <AdminDashboard /> },
          { path: 'member', element: <PlayerPerformance /> },
          { path: 'agent-performance', element: <AgentCommission /> },
        ],
        perms: ['admin', 'agent', 'subadmin']
      },

      // ADMIN
      {
        path: 'admin',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <PathAfterLogin />, index: true },
          { path: 'agents', element: <Agents /> },
          { path: 'currency', element: <Currency /> },
          { path: 'venue', element: <Venue /> },
          { path: 'groups', element: <Groups /> },
          { path: 'users', element: <Users />, perms: ['admin'] },
          { path: 'tiers', element: <Tiers /> },
        ],
        perms: ['admin', 'subadmin', 'agent'],
      },

      // GROUPS
      {
        path: '',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <PathAfterLogin />, index: true },
          { path: 'groups', element: <AgentGroup /> },
          { path: 'groups/:groupID', element: <AgentGroupDetails /> },
        ],
      },

      // EXPENSES
      {
        path: '',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          { element: <PathAfterLogin />, index: true },
          { path: 'expenses', element: <Expenses /> },
          // { path: 'groups/:groupID', element: <AgentGroupDetails /> },
        ],
        perms: ['admin', 'subadmin'],
      },

      // Main Routes
      {
        path: '*',
        element: <LogoOnlyLayout />,
        children: [
          { path: 'coming-soon', element: <ComingSoon /> },
          { path: 'maintenance', element: <Maintenance /> },
          { path: '500', element: <Page500 /> },
          { path: '404', element: <Page404 /> },
          { path: '403', element: <Page403 /> },
          { path: '*', element: <Navigate to="/404" replace /> },
        ],
      },
      // {
      //   path: '/',
      //   element: <MainLayout />,
      //   children: [
      //     { element: <HomePage />, index: true },
      //     { path: 'about-us', element: <About /> },
      //     { path: 'contact-us', element: <Contact /> },
      //     { path: 'faqs', element: <Faqs /> },
      //   ],
      // },
      { path: '*', element: <Navigate to="/404" replace /> },
    ];

    const _filteredRoutes = user?.role ? filterNavs(initialRoutes, user?.role) : initialRoutes;

    return _filteredRoutes;
  }, [user]);

  return useRoutes(filteredRoutes);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// MY PROFILE
const MyProfile = Loadable(lazy(() => import('../views/myProfile/MyProfile')));

// MEMBER PROFILE
const MemberProfile = Loadable(lazy(() => import('../views/memberPage/MemberProfile')));

// REPORTS
const GroupWinLossReport = Loadable(lazy(() => import('../views/reports/winLossReport/GroupWinLossReport')));
const PlayerPerformance = Loadable(
  lazy(() => import('../views/reports/winLossReport/playerPerformance/PlayerPerformance'))
);
const AgentCommission = Loadable(lazy(() => import('../views/reports/agentCommission/AgentCommission')));

// ADMIN
const Agents = Loadable(lazy(() => import('../views/admin/agent/Agents')));
const Currency = Loadable(lazy(() => import('../views/admin/currency/Currency')));
const Venue = Loadable(lazy(() => import('../views/admin/venue/Venue')));
const Groups = Loadable(lazy(() => import('../views/admin/groups/Groups')));
const Users = Loadable(lazy(() => import('../views/admin/user/Users')));
const Tiers = Loadable(lazy(() => import('../views/admin/tiers/Tiers')));

// AGENT
const AgentGroup = Loadable(lazy(() => import('../views/agent/agentGroup/AgentGroup')));
const AgentGroupDetails = Loadable(lazy(() => import('../views/agent/agentGroup/AgentGroupDetails')));

// EXPENSES
const Expenses = Loadable(lazy(() => import('../views/expenses/Expenses')));

// DASHBOARD
const Dashboard = Loadable(lazy(() => import('../views/Home/Dashboard')));
const AdminDashboard = Loadable(lazy(() => import('../views/Home/dashboard/AdminDashboard')));
const AgentDashboard = Loadable(lazy(() => import('../views/Home/dashboard/AgentDashboard')));

//----------------------------------------------------------------------------------------------------------------------

const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
