import moment from 'moment';
import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fFromApi(date) {
  return date ? moment(date, 'DD/MM/YYYY').format('MM/DD/YYYY') : '';
}

export function fDate(date) {
  return date ? format(new Date(date), 'dd MMMM yyyy') : '';
}

export function fDateDash(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeHSuffix(date) {
  return date ? format(new Date(date), 'dd MMM yyyy p') : '';
}

export function fDateTimeHMSuffix(date) {
  return format(new Date(date), 'MMM dd yyyy p');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fDateNumber(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fDateNumberMonth(date) {
  return format(new Date(date), 'MM/dd');
}

export function fDateToShortMonth(date) {
  return format(new Date(date), 'MMM dd');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fDateDayMonthYear(date) {
  return format(new Date(date), 'dd MMM yyyy');
}
