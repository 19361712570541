// @mui
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  DialogContent,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useDispatch, useSelector } from '../redux/store';
import { resetConfirmState, setLoading, setOpen } from '../redux/slices/confirm';
import Iconify from './Iconify';
import useLocales from '../hooks/useLocales';
// import { resetConfirmState } from '../redux/slices/confirm';

// ----------------------------------------------------------------------

export default function CustomConfirm() {
  const theme = useTheme();

  const { isLoading, open, title, subTitle, onConfirm, onCancel, isInfo, isDanger, confirmText, cancelText, hasClose } =
    useSelector((state) => state.confirm);

  const { translate } = useLocales();

  const dispatch = useDispatch();

  useEffect(() => () => setTimeout(() => dispatch(resetConfirmState()), 500), []);

  const handleClose = (e, r) => {
    if (!hasClose && r && r === 'backdropClick') {
      return false;
    }

    if (!hasClose && r && r === 'escapeKeyDown') {
      return false;
    }

    dispatch(setOpen(false));
    setTimeout(() => dispatch(resetConfirmState()), 500);
    return true;
  };

  const handleCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }

    handleClose();
  };

  const handleConfirm = async () => {
    try {
      if (onConfirm && typeof onConfirm === 'function') {
        dispatch(setLoading(true));
        await onConfirm();
        dispatch(setLoading(false));
      }
    } catch (error) {
      console.warn(error?.message);
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      sx={isDanger ? { backgroundColor: theme.palette.error.main } : {}}
      // PaperProps={{
      //   sx: {
      //     backgroundColor: 'transparent !important',
      //     backgroundImage: 'linear-gradient(180deg, #292929 0%, #020202 100%) !important',
      //     borderStyle: 'solid !important',
      //     borderColor: '#f2c556 !important',
      //     boxShadow: '0px 0px 5px 0.5px rgba(242,197,86,0.8)',
      //   },
      // }}
    >
      {hasClose ? (
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 1,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon={'ep:close'} />
        </IconButton>
      ) : (
        ''
      )}
      <DialogTitle sx={{ mt: 2 }}>
        <Typography fontWeight={'bold'}>{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ mt: 1 }}>
        <Typography component={'div'} variant="body2">
          {subTitle}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: '0 !important', justifyContent: 'space-between' }}>
        {!isInfo && (
          <Button variant="text" color="inherit" onClick={handleCancel} size="large" sx={{ p: 2, width: '50%' }}>
            {translate(cancelText)}
          </Button>
        )}

        <LoadingButton
          variant="text"
          type="submit"
          color={isDanger ? 'error' : 'primary'}
          loading={isLoading}
          disabled={isLoading}
          onClick={handleConfirm}
          size="large"
          sx={{ p: 1, width: '50%' }}
        >
          {translate(confirmText)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
