/* eslint-disable no-prototype-builtins */
import jwtDecode from 'jwt-decode';
import { fDateDash } from './formatTime';

/**
 * Check if permission code exist on users allowed permissions
 * @param {Array} permission
 * @returns {Boolean}
 */
export function hasPermission(permission) {
  try {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const { perm } = jwtDecode(accessToken);
      let isAuthorized = false;
      if (permission && perm) {
        const permList = JSON.parse(perm);
        if (permList.indexOf(permission) !== -1) {
          isAuthorized = true;
        }
      }
      return isAuthorized;
    }
  } catch (error) {
    return false;
  }

  return false;
}

/**
 * Check if variable is type Object
 * @param {Object} variable
 * @returns Boolean
 */
export const isObject = (variable) => Object.prototype.toString.call(variable) === '[object Object]';

/**
 * Filters object for sending request data,
 * @param {Object} obj
 * @returns Object
 */
export const fRequestData = (obj) => {
  const filtered = {};
  if (!isObject(obj)) return filtered;
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] !== 'undefined' && obj[key] !== null && obj[key] !== '') {
      if (Object.prototype.toString.call(obj[key]) === '[object Date]') filtered[key] = fDateDash(obj[key]);
      else if (typeof obj[key] === 'object' && obj[key]?.id) filtered[key] = obj[key]?.id;
      else filtered[key] = obj[key];
    }
  });

  return filtered;
};

/**
 * Convert object to array of formatted Object using keys parameter
 * @param {Object} obj
 * @param {Array} keys - Key names to be used where keys[0] = key and keys[1] = value
 * @returns Array Or Null
 */
export const fObjToArrayObj = (obj, keys) => {
  if (!isObject(obj)) return null;

  const arr = [];

  Object.keys(obj).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      arr.push({
        [keys[0]]: key,
        [keys[1]]: obj[key],
      });
    }
  });

  return arr;
};

export function mergeObjectsWithMatchingKey(target, source) {
  const result = { ...target };

  Object.keys(source).forEach((key) => {
    if (result.hasOwnProperty(key)) {
      if (
        source[key] !== null &&
        typeof source[key] !== 'undefined' &&
        typeof source[key] === 'object' &&
        !Array.isArray(source[key]) &&
        typeof result[key] === 'object' &&
        !Array.isArray(result[key])
      ) {
        result[key] = mergeObjectsWithMatchingKey(result[key], source[key]);
      } else {
        result[key] = source[key];
      }
    }
  });

  return result;
}

export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

export function checkIfQCExistToObj(obj, qcText, library, key, idKey) {
  let retVal = null;

  if (!obj?.id && qcText) {
    const isExist = library.find((x) => x[key] === qcText);
    if (isExist && isExist?.[idKey]) {
      retVal = isExist;
    }
  }

  return retVal;
}

export function checkIfQCExistToData(obj, qcText, library, key, idKey) {
  let retVal = obj?.id || qcText;

  if (!obj?.id && qcText) {
    const isExist = library.find((x) => x[key] === qcText);
    if (isExist && isExist?.[idKey]) {
      retVal = parseInt(isExist[idKey], 10);
    }
  }

  return obj?.id ? parseInt(retVal, 10) : retVal;
}

export const getResponseError = (error) => {
  const errorMessages = error?.response?.data?.errors;
  let errorMessage = error?.response?.data?.message || error?.response?.data?.internalMessage;

  if (typeof errorMessage === 'string') {
    try {
      if (error?.response?.data?.errorData?.length && !!error?.response?.data?.errorData[0]) {
        errorMessage += `: ${[...error?.response?.data?.errorData].join(', ')}`;
      }
    } catch (err) {
      /// ignore
    }
    return errorMessage;
  }
  if (errorMessages && Object.keys(errorMessages).length) {
    return Object.values(errorMessages).join(', ');
  }

  return `System Error: ${error.message}`;
};

export const showResponseError = (error, enqueueSnackbar) => {
  if (!enqueueSnackbar) return console.error('enqueueSnackbar parameter is missing');
  const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
  // const errorMessages = error?.response?.data?.errors;
  // let errorMessage = error?.response?.data?.message || error?.response?.data?.internalMessage;

  enqueueSnackbar(error?.message, snackbarOptions);

  // if (typeof errorMessage === 'string') {
  //   try {
  //     if (error?.response?.data?.errorData?.length && !!error?.response?.data?.errorData[0]) {
  //       errorMessage += `: ${[...error?.response?.data?.errorData].join(', ')}`;
  //     }
  //   } catch (err) {
  //     /// ignore
  //   }
  //   enqueueSnackbar(errorMessage, snackbarOptions);
  // } else if (errorMessages && Object.keys(errorMessages).length) {
  //   Object.keys(errorMessages).forEach((key) => enqueueSnackbar(errorMessages[key], snackbarOptions));
  //   // } else if (typeof error?.response?.data === 'string') {
  //   //   enqueueSnackbar(error?.response?.data, snackbarOptions);
  // } else enqueueSnackbar(`Server Error: ${error.message}`, snackbarOptions);

  return true;
};

// for filtering routes and navs
export const filterNavs = (navs, userRole) =>
  navs
    .map((nav) => {
      const hasPermission = !nav.perms || (nav.perms?.length && nav.perms.includes(userRole));

      if (nav.children) {
        const filteredChildren = filterNavs(nav.children, userRole); // Do recursive for nested array of objects

        if (filteredChildren?.length > 0 && hasPermission) {
          return { ...nav, children: filteredChildren };
        }
      }

      return hasPermission ? nav : null;
    })
    .filter((nav) => nav !== null);