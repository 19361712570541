import React from 'react';
import axios from 'axios';
import { Redirect, useHistory } from 'react-router-dom';
import app from '../App';

/**
 * @description
 * axios instance with default
 * baseUrl & headers etc. for global use
 */
const conObj = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    // headers, without this, it will throw unsupported media type error
    'content-type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    Accept: 'application/json',
    // Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
  },
});

conObj.interceptors.request.use((request) => {
  // Need to check if jwt already expired?
  /**
   * Moved this piece of code here so that localStorage.getItem("jwt")
   * is always getting the latest value.
   * If follow old settings, still need to refresh the whole site to
   * reflect the latest localStorage values
   * */
  request.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

  // console.log(request.params && Object.keys(request.params)?.length && Object.hasOwn(request.params, 'page'));
  if (request.params) {
    if ('page' in request.params) {
      request.params = {
        ...request.params,
        page: request.params.page + 1,
      };
    }
  }

  return request;
});

conObj.interceptors.response.use(
  (response) => response,
  // saveLogs(response);
  (error) => {
    if (!axios.isCancel(error)) {
      if (error.response?.status === 401) {
        // const lastPath = window.location.hash;
        // sessionStorage.setItem('lastPath', lastPath.substring(1, lastPath.length));
        window.location.replace('/');
      }
      // if (error.response && error.response.status === 400) {
      //   // Extract the error message from the response payload
      const errorMessage = error?.response?.data || 'An error occurred';
      //   console.error('Error:', errorMessage);
      //   return Promise.reject(errorMessage); // Reject the promise with the error message
      // }
      return Promise.reject(errorMessage);
    }
  }
);

// function saveLogs(req_data) {
//   const data = {
//     secret: 'secret',
//     req_data: req_data,
//     jwt: localStorage.getItem('jwt'),
//   };
//   axios
//     .post(process.env.REACT_APP_LOGGER_API, data, axiosConfig)
//     .then((res) => {
//       //do nothing
//     })
//     .catch((err) => {
//       console.log('Something went wrong while calling save logs: ' + err);
//     });
// }

export default conObj;
