// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_MY_PROFILE = '/profile';
const ROOTS_SUB_ACCOUNT = '/sub-account';
const ROOTS_REPORT = '/report/';
const ROOTS_ADMIN = '/admin/';
const ROOTS_AGENT = '/agent/';
const ROOTS_GROUPS = '/groups';
const ROOTS_EXPENSES = '/expenses';

const ROOTS_DASHBOARD = '/dashboard';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  agents: path(ROOTS_ADMIN, 'agents'),
  products: path(ROOTS_ADMIN, 'products'),
  venue: path(ROOTS_ADMIN, 'venue'),
  currency: path(ROOTS_ADMIN, 'currency'),
  groups: path(ROOTS_ADMIN, 'groups'),
  users: path(ROOTS_ADMIN, 'users'),
  tiers: path(ROOTS_ADMIN, 'tiers'),
  adjustments: path(ROOTS_ADMIN, 'adjustments'),
};

export const PATH_AGENT = {
  root: ROOTS_AGENT,
  groups: path(ROOTS_AGENT, 'groups'),
};

export const PATH_GROUPS = {
  root: ROOTS_GROUPS,
};

export const PATH_EXPENSES = {
  root: ROOTS_EXPENSES,
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_MY_PROFILE = {
  root: ROOTS_MY_PROFILE,
};

export const PATH_SUB_ACCOUNT = {
  root: ROOTS_SUB_ACCOUNT,
};

export const PATH_REPORTS = {
  root: ROOTS_REPORT,
  groupPerformance: path(ROOTS_REPORT, 'group'),
  playerPerformance: path(ROOTS_REPORT, 'member'),
  agentPerformance: path(ROOTS_REPORT, 'agent-performance'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  // adminDashboard: path(ROOTS_DASHBOARD, '/admin'),
  // agentDashboard: path(ROOTS_DASHBOARD, '/agent'),
};
